import styled from "styled-components";
import { EventCode, ProcessedActivity } from "../types";
import { COLORS, flexRow } from "./style";
import { Typography } from "./Typography";
import { ReactComponent as Cube222 } from "./icons/events/222.svg";
import { ReactComponent as Cube333 } from "./icons/events/333.svg";
import { ReactComponent as BF } from "./icons/events/333bf.svg";
import { ReactComponent as FMC } from "./icons/events/333fm.svg";
import { ReactComponent as MBF } from "./icons/events/333mbf.svg";
import { ReactComponent as OH } from "./icons/events/333oh.svg";
import { ReactComponent as Cube444 } from "./icons/events/444.svg";
import { ReactComponent as BF444 } from "./icons/events/444bf.svg";
import { ReactComponent as Cube555 } from "./icons/events/555.svg";
import { ReactComponent as BF555 } from "./icons/events/555bf.svg";
import { ReactComponent as Cube666 } from "./icons/events/666.svg";
import { ReactComponent as Cube777 } from "./icons/events/777.svg";
import { ReactComponent as Clock } from "./icons/events/clock.svg";
import { ReactComponent as Mega } from "./icons/events/minx.svg";
import { ReactComponent as Pyra } from "./icons/events/pyram.svg";
import { ReactComponent as Skewb } from "./icons/events/skewb.svg";
import { ReactComponent as SQ1 } from "./icons/events/sq1.svg";
import { ROOMS, STAGES } from "./constants";

type SVGComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

const iconMap: Record<EventCode, SVGComponent | null> = {
  "222": Cube222,
  "333": Cube333,
  "333bf": BF,
  "333fm": FMC,
  "333mbf": MBF,
  "333oh": OH,
  "444": Cube444,
  "555": Cube555,
  "444bf": BF444,
  "555bf": BF555,
  "666": Cube666,
  "777": Cube777,
  clock: Clock,
  minx: Mega,
  pyram: Pyra,
  skewb: Skewb,
  sq1: SQ1,
  other: null,
};

const ICON_SIZE = "36px";
const BREAK_POINT = "1150px";

const Base = styled.div`
  border: 1px solid ${COLORS.BLACK};
  display: grid;
  grid-template-columns: 1.5fr 1fr 2fr;

  #name-icon {
    justify-content: flex-end;
    min-height: ${ICON_SIZE};

    #icon {
      margin-right: 12px;
    }
  }

  @media (max-width: ${BREAK_POINT}) {
    row-gap: 18px;
    column-gap: 12px;
    grid-template-columns: repeat(2, 1fr);
    #name-icon {
      grid-column: span 2;
      justify-content: center;
    }
    #room {
      justify-content: center;
    }
  }
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 12px;
`;

const GridItem = styled.div`
  ${flexRow}
`;

const RoomIndicators = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${BREAK_POINT}) {
    grid-template-columns: 1fr;
  }
  gap: 4px;
`;

const roomStageColorMap: Record<string, string> = {
  [ROOMS.FRONTENAC]: COLORS.BLACK,
  [ROOMS.METRO]: COLORS.BLACK,
  [STAGES.B]: "#2e77d6",
  [STAGES.R]: "#da1025",
  [STAGES.G]: "#54a512",
  [STAGES.O]: "#ec9a27",
  [STAGES.Y]: "#dad307",
};

const roomStageFontColorMap: Record<string, string> = {
  [ROOMS.FRONTENAC]: COLORS.WHITE,
  [ROOMS.METRO]: COLORS.WHITE,
  [STAGES.B]: COLORS.BLACK,
  [STAGES.R]: COLORS.BLACK,
  [STAGES.G]: COLORS.BLACK,
  [STAGES.O]: COLORS.BLACK,
  [STAGES.Y]: COLORS.BLACK,
};

const RoomStagePill = styled.div<{ $roomOrStageName: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8rem;
  border-radius: 4px;
  background-color: ${(props) => roomStageColorMap[props.$roomOrStageName]};
  color: ${(props) => roomStageFontColorMap[props.$roomOrStageName]};
  padding: 6px;
`;

const MEALS = new Set(["Lunch", "Dinner"]);
const SHOW_ROOM_ONLY = new Set([
  "Staff Competition",
  "Awards",
  "Seminars",
  "Rubik’s North American Cup",
]);

export const ScheduleItem = ({
  startTime,
  endTime,
  name,
  event,
  roomName,
  stages,
}: ProcessedActivity) => {
  const SVG = iconMap[event];

  return (
    <Base>
      <GridItem>
        <Typography>
          {startTime} — {endTime}
        </Typography>
      </GridItem>
      <GridItem id="room">
        <RoomIndicators>
          {!MEALS.has(name) && (
            <>
              {!SHOW_ROOM_ONLY.has(name) &&
                stages.map((stage) => (
                  <RoomStagePill key={stage} $roomOrStageName={stage}>
                    {stage}
                  </RoomStagePill>
                ))}
              <RoomStagePill $roomOrStageName={roomName}>
                {roomName}
              </RoomStagePill>
            </>
          )}
        </RoomIndicators>
      </GridItem>
      <GridItem id="name-icon">
        {SVG !== null && SVG !== undefined && (
          <SVG width={ICON_SIZE} height={ICON_SIZE} id="icon" />
        )}
        <Typography isBold>{name}</Typography>
      </GridItem>
    </Base>
  );
};
