/* eslint-disable jsx-a11y/anchor-has-content */
import { Trans, useTranslation } from "react-i18next";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Link } from "../components/Link";
import { ActionButton } from "../components/LinkButton";
import { IMAGES, Picture } from "../components/Picture";
import { Typography } from "../components/Typography";
import { getLocalizedPath } from "../services/locale";

export const Register = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section>
          <Typography as="h1" data-testid="header">
            {t("registration")}
          </Typography>
          <Line />
          <Typography>{t("reg.mainBody")}</Typography>
          <br />
          <br />
          <ActionButton
            to="https://www.worldcubeassociation.org/competitions/NAC2022/register"
            data-testid="register-cta"
          >
            {t("register")}
          </ActionButton>
        </Section>
        <Section>
          <Picture id={IMAGES.torontoSign[2]} />
        </Section>
      </Layout>

      <Layout>
        <Section>
          <Typography as="h2" data-testid="sub-header">
            {t("reg.cancelOrEdit.title")}
          </Typography>
          <Line />
          <Typography>
            {[1, 2, 3].map((n) => t(`reg.cancelOrEdit.body.${n}`)).join("\n\n")}
          </Typography>
          <br />
          <br />
          <Typography>
            <Trans
              i18nKey="reg.dontForget"
              components={{
                a: (
                  <Link
                    to={getLocalizedPath("en", "events")}
                    data-testid="events-link"
                  />
                ),
              }}
            />
          </Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.ttc[4]} />
        </Section>
      </Layout>
    </>
  );
};
