export const LOCALES = { en: "en", fr: "fr", es: "es" } as const;
export const DEFAULT_LOCALE = LOCALES.en;

export const resources = {
  [LOCALES.en]: {
    translation: {
      results: "Results",
      NAC2022: "Rubik’s WCA North American Championship",
      // Routes //
      registration: "Registration",
      schedule: "Schedule",
      travel: "Travel",
      faq: "FAQ",
      contact: "Contact",
      events: "Events",
      "covid-19": "COVID-19",
      staff: "Staff",
      "rubiks-cup": "Rubik’s Cup",
      seminars: "Seminars",
      groups: "Groups",
      // Routes //
      // MISC //
      moreInfo: "More info",
      register: "Register",
      wantToGetInTouch: "Want to get in touch with us?",
      followUs: "Follow us",
      ourSponsors: "Our sponsors",
      toronto: "Toronto, Canada",
      july7to10: "July 7-10, 2022",
      registrationOpensOn: "Registration opens on April 4, 2022",
      canadaLandsNotice:
        "CN Tower designs are owned and controlled by Canada Lands Company Limited. Used under license.",
      rubiksTm:
        "RUBIK’S TM & © 2022 SMTUK, used under license. All rights reserved.",
      // MISC //
      contactPage: {
        send: "Send",
        message: "Message",
        subject: "Subject",
        email: "Email address",
        name: "Name",
      },
      faqs: {
        q: {
          "cancelling-registration":
            "Can I cancel my registration and get my registration fee refunded?",
          "are-spectators-allowed":
            "Are spectators allowed at the competition? Is there a spectator fee?",
          "photos-and-videos":
            "Can I take photos and make video recordings at the competition?",
          "can-my-organization-sponsor":
            "Can my company or organization sponsor the competition?",
          "how-do-i-qualify":
            "How do I qualify to compete in Rubik’s WCA North American Championship 2022?",
          "can-i-register-if-i-dont-qualify":
            "Can I register for an event if I don’t yet qualify, but think I will by the deadline?",
          "what-happens-if-dont-qualify":
            "What happens if I register for an event and I don’t qualify before the deadline?",
          "register-without-any-results":
            "Can I register without having any official results?",
          "hotel-rate": "Is there a special hotel rate for the competition?",
          "air-travel":
            "Is there a preferred airline and airfare discount for the competition?",
          "airport-to-venue":
            "How do I get to the venue from Pearson International Airport?",
          "closest-train-station":
            "What is the closest transit station to the venue?",
        },
        a: {
          "cancelling-registration":
            "Competitors who cancel their registration before May 31, 2022 at 6:00 PM Eastern Time will receive a 50% refund. After this date, no refunds will be issued.",
          "are-spectators-allowed":
            "With the exception of the <a>Rubik’s North American Cup</a> event on Saturday evening, spectating is restricted to competitors and their guests. Two guests will be allowed per competitor. Upon checking in at the competition, competitors will receive two guest lanyards that their guest(s) must wear for the duration of the event. All attendees must be wearing an event lanyard while inside the venue, and those not wearing one will be asked to leave.\n\nA livestream of the competition will be available for those who are unable to attend in-person.\n\nThere are no spectator fees.",
          "photos-and-videos":
            "Personal and non-commercial photos and video recordings are allowed. All commercial media must contact the Organizing Committee to obtain a media pass.",
          "can-my-organization-sponsor":
            "Sponsorship opportunities are available. Please contact the Organizing Committee for our sponsorship package.",
          "how-do-i-qualify":
            "To qualify to compete in an event at Rubik’s WCA North American Championship 2022, you must have an official WCA result better than the set qualification time by June 13, 2022 (inclusive). The list of qualification times for Rubik’s WCA North American Championship 2022 can be found here.\n\nYou can obtain an official WCA result at any upcoming official WCA competition. A list of upcoming competitions can be found at <a>worldcubeassociation.org/competitions</a>.",
          "can-i-register-if-i-dont-qualify":
            "Yes, you can register for an event even if you don’t yet have an official WCA result that qualifies for Rubik’s WCA North American Championship 2022. To qualify, you must compete in that event and obtain a result that meets the qualification condition at an official WCA competition that is finished by June 13, 2022 (inclusive).",
          "what-happens-if-dont-qualify":
            "If you do not have an official WCA result that meets the qualification condition by June 13, 2022 (inclusive), the event(s) you do not qualify for will be removed from your registration. You will receive an email notifying you of which events were removed from your registration.",
          "register-without-any-results":
            "No. You must already have at least one result from a WCA competition that meets the qualification requirement in order to register. The list of qualification times for Rubik’s WCA North American Championship 2022 can be found here.",
          "hotel-rate":
            "The competition venue, the Westin Harbour Castle, is offering a special guest room rate for those attending WCA North American Championship 2022. See our <a>Travel page</a> for more information.",
          "air-travel":
            "Air Canada is the Official Canadian Airline for WCA North American Championship 2022. A promotional code for up to 10% off airfare is available to those attending WCA North American Championship 2022. See our <a>Travel page</a> for more information.",
          "airport-to-venue":
            "UP Express is a train that connects Pearson International Airport to Toronto’s Union Station, which is just a 10-minute walk from the competition venue. Additionally, ride share and taxi services are available throughout the Greater Toronto Area. See our <a>Travel page</a> for more information.",
          "closest-train-station":
            "Toronto’s Union Station is the closest transit station to the venue. Both TTC and GO Transit service operate at Union Station. See our <a>Travel page</a> for more information.",
        },
        general: "General",
        qualification: "Qualification",
        travel: "Travel",
      },
      home: {
        ourSponsorsBody:
          "Thank you to our sponsors, who make this competition possible!",
        rubiksCaption: "Rubik’s is the title sponsor of this competition.",
        wcaCaption:
          "The WCA will recognize the results and records at the competition.",
        hostCity: {
          title: "Host City",
          body: "We’re excited to welcome you to Toronto, Ontario, Canada for the inaugural WCA North American Championship.",
          competitionVenue: "Competition venue:",
          address:
            "Westin Harbour Castle Conference Centre\n11 Bay Street, Toronto, Ontario, Canada",
        },
        keyDates: {
          title: "Key Dates",
          open: {
            when: "April 4, 2022 (9:00 PM Eastern Time):",
            what: "Registration opens (CA$160)",
          },
          refund: {
            when: "May 31, 2022 (6:00 PM Eastern Time):",
            what: "Refund deadline (50%)",
          },
          qualification: {
            when: "June 13, 2022:",
            what: "Deadline to meet qualification conditions*",
          },
          close: {
            when: "June 20, 2022 (6:00 PM Eastern Time):",
            what: "Last day to register or to modify existing registration",
          },
          comp: {
            when: "July 7-10, 2022:",
            what: "Rubik’s WCA North American Championship 2022",
          },
          qualificationInfo:
            "* To qualify for an event, you must satisfy the <a>listed qualification requirement</a> in a WCA competition by June 13, 2022 (inclusive).",
        },
        mainInfo:
          "Registration opens on the WCA website on April 4, 2022 at 9:00 PM (Eastern Time).",
        cost: "Fee: CA$160",
        competitorLimit: "Competitor Limit: 550",
      },
      schedulePage: {
        body: "Here is a full schedule of events. This schedule is subject to change by the Organizing Committee at any time.\n\nAdditionally, you can view the schedule on the <wcaWebsiteSchedule>WCA website</wcaWebsiteSchedule> and as a <pdfSchedule>downloadable PDF</pdfSchedule>.",
      },
      reg: {
        mainBody:
          "All registrations must be completed through the WCA website between April 4, 2022 at 9:00 PM (Eastern Time) and June 20, 2022 at 6:00 PM (Eastern Time). There will be no on-site registrations during the competition. The registration fee is $160 (Canadian dollars).\n\nYour registration is not complete until you have paid the registration fee in full and your name appears on the competitors list.",
        cta: "register",
        cancelOrEdit: {
          title: "CANCEL OR EDIT YOUR REGISTRATION",
          body: {
            1: "If you would like to cancel or edit your registration (such as adding and removing events), please send an email to the Organizing Committee using the email address you used to register for the competition. A simple message such as “I would like to add Square-1 to my registration” will suffice. We will respond to confirm that your registration has been modified.",
            2: "Refund policy for cancelled registrations: 50% of the registration fee will be refunded to those who cancel their registration before May 31, 2022 at 6:00 PM Eastern Time. After this date, no refunds will be issued.",
            3: "After online registration closes on June 20, 2022 at 6:00 PM (Eastern Time), you will no longer be able to add events to your registration, although you may still remove events by contacting the Organizing Committee.",
          },
        },
        dontForget:
          "Don’t forget! There are qualification conditions to compete in Rubik’s WCA North American Championship 2022. Qualification times can be found <a>here</a>.",
      },
      seminarsPage: {
        title: "Seminars",
        body: "We will be hosting cubing-related seminars on the evening of Friday, July 8 at Rubik’s WCA North American Championship 2022. Join us starting at 8:00 PM (Eastern Time) in the Metropolitan Ballroom.",
        table: {
          time: "Time",
          presenters: "Presenters",
          title: "Title",
        },
      },
      eventsPage: {
        title: "Events & Qualification Requirements",
        beforeTable:
          "Here is a list of the events and qualification conditions for Rubik’s WCA North American Championship 2022:",
        afterTable: {
          1: "In order to qualify for an event, you must meet the qualification condition at an official WCA competition that finished taking place by June 13, 2022.",
          2: "After June 20, 2022, you will be removed from the events you didn’t qualify for, if any. You will receive an email notifying you of which events were removed from your registration and why you didn’t qualify for them.",
          3: "Number of rounds per event, advancement conditions, qualification requirements and time limits are subject to change to by the Organizing Committee. Registered competitors will be notified of any changes.",
        },
        prizes: {
          title: "PRIZES",
          ranks: ["1st place", "2nd place", "3rd place"],
          333: {
            title: "3x3x3 Cube",
            amounts: ["$1,500", "$1,000", "$500"],
          },
          other: {
            title: "Other WCA Events",
            amounts: ["$500", "$250", "$100"],
          },
          canadianDollars: "All amounts are in Canadian dollars.",
        },
      },
      covid: {
        title: "COVID-19 Information",
        lastUpdated: "Last update: June 24, 2022",
        p: {
          1: "The Organizing Committee is committed to creating a safe and healthy environment for competitors, spectators and our community. We are and will be monitoring the COVID-19 situation closely and adjusting our approach and policies accordingly.",
          2: "At a minimum, Rubik’s WCA North American Championship 2022 will follow all public health measures in place for Toronto, Ontario, Canada at the time of the competition. The Organizing Committee may impose its own health and safety measures for the event at its discretion.",
          3: "All attendees (including competitors and spectators) at Rubik’s WCA North American Championship 2022 will be expected to adhere to the following COVID-19 health and safety measures while at the competition venue:",
          4: "Adherence to these measures is required regardless of vaccination status. Adherence to all health and safety measures in place at Rubik’s WCA North American Championship 2022 is mandatory for all attendees. Failing to follow the measures in place will result in consequences determined by the Organizing Committee, which can include removal from the competition venue and disqualification from the competition.",
          5: "We know the restriction on guests will be disappointing for some competitors and community members. A livestream of the competition will be available for those who are unable to attend in-person.",
          6: "These measures are subject to change at any time in response to new information regarding the COVID-19 pandemic or new measures or recommendations from public health officials. It is the responsibility of each competitor to research and adhere to public health measures, including those related to travel and/or entering Canada.",
          7: "Thank you for helping us run a safe and healthy competition for our community!",
        },
        ul: {
          1: "Anyone <a1>experiencing symptoms likely to be COVID-19</a1> or who has tested positive for COVID-19 must self-isolate and should not attend the competition.",
          2: "Individuals must wear a well-fitting mask that covers their nose, mouth and chin, as <a2>advised by Toronto public health</a2>. A medical mask or respirator is recommended for increased protection.",
          3: "Individuals must maintain <i>at least</i>  two metres or six feet of distance from anyone who is not a member of their household when removing their mask to eat or drink. Alternatively, individuals should leave the venue to eat or drink.",
          4: "With the exception of the <rubiksCup>Rubik’s North American Cup</rubiksCup> event on Saturday evening, spectating is restricted to competitors and their guests. Two guests will be allowed per competitor. Upon checking in at the competition, competitors will receive two lanyards that their guest(s) must wear for the duration of the event.",
        },
      },
      travelPage: {
        venue: {
          body: "The competition will be held at the Westin Harbour Castle Conference Centre in Toronto, Ontario, Canada.",
          address: "Address: 11 Bay Street, Toronto, Ontario",
          rooms:
            "Main Room: Metropolitan Ballroom\nSide Room: Frontenac Ballroom\nRegistration: Metropolitan Ballroom Lobby",
          moreInfo: "More information coming soon!",
        },
        hotel: {
          title: "HOTEL",
          body: "The Westin Harbour Castle (attached to the competition venue) is offering a special booking rate for guest rooms.\n\nGuests who make reservations under the group block will receive complimentary internet access in guest rooms.",
          reservation: {
            header: "To make a reservation under our group rate:",
            phone: {
              what: "By phone:",
              details:
                "Call 1-888-627-8559 (toll-free) and state that you are part of the Speedcubing Canada Room Block.",
            },
            online: {
              what: "Online:",
              details: "Book using this <a>special link</a>.",
            },
            extra:
              "Important: To avail of the group rate, reservations must be made by June 15, 2022.",
          },
        },
        air: {
          title: "AIR TRAVEL",
          body: "Air Canada is the Official Canadian Airline for Rubik’s WCA North American Championship 2022. The following promotional code can be used for up to 10% off domestic and international Air Canada flights, for travel between Thursday, June 30, 2022 and Sunday, July 17, 2022, to either of Toronto’s airports (Toronto Pearson International Airport (YYZ) and Billy Bishop Toronto City Airport (YTZ)). <i>Terms and conditions apply. <a>See Air Canada’s website for details.</a><i>",
          code: "Air Canada Promotional Code: P8HMTGP1",
        },
        local: {
          title: "LOCAL TRANSPORTATION",
          body: {
            1: "The venue is a 10-minute walk from <a1>Toronto’s Union Station</a1>.",
            2: "<b>UP Express</b> connects Toronto Pearson International Airport to Union Station in just 25 minutes. Trains leave every 15 minutes with the first train departing Pearson Station at 05:30 and the last train departing at 01:00. Visit <a2>UPexpress.com</a2> before July 13, 2022 and use the promotional code <b>WCA2022</b> to receive a 25% discount on one-way and return adult tickets.",
            3: "The <b><a3a>Toronto Transit Commission (TTC)</a3a></b> operates buses, street cars and subway service throughout the city. Line 1 of the subway system stops at <a3b>Union Station</a3b> with trains every 2-3 minutes. ",
            4: "<b><a4>GO Transit</a4></b> provides buses and trains throughout the Greater Toronto Area. Travel to and from Toronto’s Union Station is a convenient option for competitors who are travelling from neighbouring cities.",
            5: "Ride share and taxi services are available throughout the Greater Toronto Area.",
          },
        },
        tourist: {
          title: "TOURIST INFORMATION",
          body: "As the country’s largest and most-visited destination, Toronto is Canada’s Downtown. With over 230 nationalities and 140 languages and dialects, Toronto has made a name for itself as one of the most multicultural cities in the world. Experience this acclaimed cultural vibrancy through the city’s cuisine, art, shopping, festivals and attractions.",
          cta: "Visit Tourism Toronto’s website",
        },
      },
      staffPage: {
        title: "Staff",
        body: "Rubik’s WCA North American Championship 2022 is run entirely by volunteers. If you would like to apply to be a volunteer staff member at the competition, please fill out the application form <a>here</a>.",
        roles: "Roles include running, judging, scrambling and data entry.",
        vaccine:
          "As of now, staff at Rubik’s WCA North American Championship 2022 will be required to be fully vaccinated against COVID-19.",
        closed:
          "The staff application form is now closed. Thank you to everyone who submitted an application.",
      },
      rubiksCup: {
        title: "Rubik’s North American Cup",
        body: "We are proud to present Rubik’s North American Cup at Rubik’s WCA North American Championship 2022. This special exhibition event will be held on Saturday, July 9 starting at 8:00 PM and will be open to the public for spectating.",
        reWatchTheEvent: "Re-watch the event",
        format: {
          heading: "Format",
          1: "This will be a bracketed event of 16 teams.",
          2: "Each team will have three members representing the same North American country.",
          3: "The bracket will be filled first with the fastest team from each eligible North American country, followed by the next fastest teams regardless of country.",
          4: "Only North American teams are eligible.",
          5: "Team members will consecutively complete a single 3x3x3 solve. The team with the faster cumulative time will move on in the bracket.",
          6: "In the event of a tie, the fastest single from each team will determine the winner.",
          7: "Standard WCA penalties (e.g. misalignment, timer stops/starts) apply for the solves.",
          8: "Team members will supply their own cube.",
          9: "This is an unofficial team event, no results will be stored in the WCA database.",
          10: "If no relevant WCA regulation can be applied to resolve an incident, the event’s judges may use discretion to resolve the issue with fair play and sportsmanship as the motivations for the decision.",
        },
        submitYourTeam: {
          title: "Submit your team",
          body: "Use <a>this Google form</a> to submit your team. You will be contacted via the email address associated with your WCA profile. Qualification and seeding will be determined by your official WCA result in 3x3 average as of June 24, 2022. The deadline to submit a team is June 24, 2022 at 6:00 PM (Eastern Time).",
          cta: "Submit your team",
          closed:
            "Submissions are now closed. Thank you to everyone who submitted a team.",
        },
        spectatorInfo: {
          title: "Spectator info",
          body: "Although spectators for the main competition events are limited due to COVID-19, we are pleased to open this exhibition event to the public for spectating on a first-come, first-serve basis.\n\nAll spectators are expected to adhere to the event’s <a>COVID-19 policies</a>, which includes wearing a mask while inside the venue.\n\n",
          venue:
            "Venue:\nWestin Harbour Castle Conference Centre\nMetropolitan Ballroom\n11 Bay Street, Toronto, Ontario, Canada\n\n",
          time: "Time: Saturday, July 9, 2022, 8:00 PM (Eastern Time)",
        },
        teams: {
          title: "Teams",
          body: "List of teams by seed (determined by official WCA results in 3x3 average as of June 24, 2022):",
          seed: "Seed",
          name: "Name",
          teamMembers: "Team Members",
          time: "Cumulative Time",
        },
        bracket: {
          title: "Bracket",
        },
      },
      groupsPage: {
        title: "Groups",
        body:
          "Please find your name below to download a PDF version of your personal schedule, which indicates the times you are scheduled to compete in the event(s) you are registered for.\n\n" +
          "The colour at the start of your group name indicates where you should compete. In the Metropolitan Ballroom (main room), there will be four colour-coded stages: red, blue, green and orange. In the Frontenac Ballroom (side room) is the yellow stage. For example: 3x3x3 Cube Round 1, Blue Group 4 will compete on the blue stage in the Metropolitan Ballroom.",
        competitor: "Competitor",
        filter: "Filter by name",
      },
    },
  },
  [LOCALES.fr]: {
    translation: {},
  },
  [LOCALES.es]: {
    translation: {},
  },
} as const;

export type FAQKeys = keyof typeof resources.en.translation.faqs.a;

export const getLocalizedPath = (
  locale: keyof typeof LOCALES,
  path?: string | null | undefined
) => `/${locale}/${path ?? ""}`;
