import { STAGES } from "../components/constants";
import { Activity, EventCode, ProcessedEventDay, Schedule } from "../types";
import wcif from "./wcif.json";

export const getSchedule = async (): Promise<Schedule> => {
  return wcif.schedule;
};

const dateLocale = "en-US" as const;

const dayKeyOptions = {
  weekday: "short",
  timeZone: "America/Toronto",
} as const;

const dateOptions = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  timeZone: "America/Toronto",
} as const;

const timeOptions = {
  timeStyle: "short",
  timeZone: "America/Toronto",
} as const;

const STAGE_RANKS = {
  [STAGES.B]: 1,
  [STAGES.R]: 2,
  [STAGES.G]: 3,
  [STAGES.O]: 4,
  [STAGES.Y]: 5,
};

const sortStages = (a: string, b: string) => STAGE_RANKS[a] - STAGE_RANKS[b];

export const processSchedule = (schedule: Schedule) => {
  const [venue] = schedule.venues;
  const allActivities: Array<Activity & { roomName: string }> = [];

  venue.rooms.forEach((room) =>
    room.activities.forEach((activity) => {
      allActivities.push({ ...activity, roomName: room.name });
    })
  );

  allActivities.sort((a, b) =>
    (a.startTime + a.endTime).localeCompare(b.startTime + b.endTime)
  );

  const days: Record<string, ProcessedEventDay> = {};

  allActivities.forEach(
    ({
      id,
      name,
      activityCode,
      startTime,
      endTime,
      roomName: roomAndStage,
    }) => {
      const [room, stage] = roomAndStage.split("-");
      const roomName = room.trim();
      const stageName = (stage || STAGES.Y).trim();

      const start = new Date(startTime);
      const end = new Date(endTime);

      const dayKey = new Intl.DateTimeFormat(dateLocale, dayKeyOptions)
        .format(start)
        .toLowerCase();

      const formattedDate = new Intl.DateTimeFormat(
        dateLocale,
        dateOptions
      ).format(start);

      if (!days.hasOwnProperty(dayKey)) {
        days[dayKey] = {
          formattedDate,
          events: {},
        };
      }

      const formattedStartTime = new Intl.DateTimeFormat(
        dateLocale,
        timeOptions
      ).format(start);

      const formattedEndTime = new Intl.DateTimeFormat(
        dateLocale,
        timeOptions
      ).format(end);

      const eventCode = activityCode.split("-")[0] as EventCode;

      const event = {
        id,
        name,
        event: eventCode,
        startTime: formattedStartTime,
        endTime: formattedEndTime,
        roomName,
        stages: [stageName],
        start,
        end,
      };

      if (!days[dayKey].events.hasOwnProperty(activityCode)) {
        days[dayKey].events[activityCode] = event;
      } else {
        if (days[dayKey].events[activityCode].end < end) {
          days[dayKey].events[activityCode].endTime = formattedEndTime;
        }

        if (days[dayKey].events[activityCode].start > start) {
          days[dayKey].events[activityCode].startTime = formattedStartTime;
        }

        days[dayKey].events[activityCode].stages.push(stageName);
        days[dayKey].events[activityCode].stages.sort(sortStages);
      }
    }
  );

  return days;
};
