import { Trans, useTranslation } from "react-i18next";
import { Typography } from "../components/Typography";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Link } from "../components/Link";

export const Staff = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section>
          <Typography as="h1">{t("staffPage.title")}</Typography>
          <Line />
          <Typography isBold data-testid="application-closed">
            {t("staffPage.closed")}
          </Typography>
          <br />
          <br />
          <Typography>
            <Trans
              i18nKey="staffPage.body"
              components={{
                a: (
                  <Link
                    to="https://forms.gle/yuRZsvg6WU1uU6oM9"
                    data-testid="staff-application-form"
                  />
                ),
              }}
            />
          </Typography>
          <br />
          <br />
          <Typography>{t("staffPage.roles")}</Typography>
          <br />
          <br />
          <Typography>{t("staffPage.vaccine")}</Typography>
        </Section>
      </Layout>
    </>
  );
};
