import { useEffect } from "react";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import { resources, DEFAULT_LOCALE } from "./services/locale";
import { PageBase } from "./components/PageBase";
import { GlobalStyle } from "./components/style";
import {
  Home,
  FAQ,
  Schedule,
  Travel,
  Register,
  Covid,
  Events,
  Staff,
  RubiksCup,
  Seminars,
  Groups,
} from "./pages";

i18n.use(initReactI18next).init({
  resources,
  lng: DEFAULT_LOCALE,
  interpolation: {
    escapeValue: false,
  },
});

export const App = () => {
  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <>
      <GlobalStyle />
      <BrowserRouter>
        <Routes>
          <Route path="/:locale" element={<PageBase />}>
            <Route index element={<Home />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="faq/:questionId" element={<FAQ />} />
            <Route path="schedule" element={<Schedule />} />
            <Route path="schedule/:date" element={<Schedule />} />
            <Route path="travel" element={<Travel />} />
            <Route path="registration" element={<Register />} />
            <Route path="covid-19" element={<Covid />} />
            <Route path="events" element={<Events />} />
            <Route path="staff" element={<Staff />} />
            <Route path="rubiks-cup" element={<RubiksCup />} />
            <Route path="seminars" element={<Seminars />} />
            <Route path="groups" element={<Groups />} />
          </Route>
          <Route path="*" element={<Navigate to={DEFAULT_LOCALE} replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
