import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Label, TextInput } from "../components/Input";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Link } from "../components/Link";
import { Table, TableContainer, TableData } from "../components/Table";
import { Typography } from "../components/Typography";
import competitors from "../data/competitors.json";

const FILTER_ID = "filter-by-name";
const NAMES_ID = "competitor-names";

const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Groups = () => {
  const isLocalhost = window.location.href.includes("localhost");
  const downloadLink = isLocalhost
    ? "http://localhost:9300/pdf/"
    : "https://nac2022.speedcubingcanada.org/pdf/";

  const [nameFilter, setNameFilter] = useState("");
  const { t } = useTranslation();

  const filteredCompetitors =
    nameFilter.length > 0
      ? competitors.filter(({ name }) =>
          name.toUpperCase().includes(nameFilter.toUpperCase())
        )
      : competitors;

  return (
    <Layout>
      <Section>
        <Typography as="h1">{t("groupsPage.title")}</Typography>
        <Line />
        <Typography>{t("groupsPage.body")}</Typography>
        <br />
        <br />
        <FilterContainer>
          <Label htmlFor={FILTER_ID} isBold>
            {t("groupsPage.filter")}
          </Label>
          <TextInput
            id={FILTER_ID}
            list={NAMES_ID}
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
          <datalist id={NAMES_ID}>
            {competitors.map(({ name, wcaId }) => (
              <option key={wcaId} value={name} />
            ))}
          </datalist>
        </FilterContainer>

        <TableContainer>
          <Table data-testid="table">
            <thead>
              <tr>
                <td>
                  <Typography isBold>{t("groupsPage.competitor")}</Typography>
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredCompetitors.map(({ name, wcaId }) => (
                <tr key={wcaId}>
                  <TableData>
                    <Link to={`${downloadLink}${wcaId}.pdf`}>
                      {name} ({wcaId})
                    </Link>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Section>
    </Layout>
  );
};
