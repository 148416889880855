import styled from "styled-components";

const Image = styled.img<{ $nativeAspectRatio: boolean }>`
  object-fit: cover;
  width: 100%;
  aspect-ratio: ${(props) =>
    props.$nativeAspectRatio ? undefined : "16 / 10"};
`;

export const IMAGES = {
  skyline: {
    1: "qlKaN7eqay8",
    2: "hRg1KL4-AUE",
    3: "6wVqCeK030Y",
    4: "SYHsBaRDWBQ",
    5: "IJHVtGZrDDE",
  },
  torontoSign: {
    1: "Onadzzr1yBU",
    2: "vsFCo5xnpZg",
    3: "DnATKk5tGns",
  },
  ttc: {
    1: "Nm7pTBPkGRo",
    2: "u_KQbigI68g",
    3: "1zqmWyrUOoU",
    4: "aNHloYjAkC8",
  },
  flatiron: {
    1: "W_MUqtuHwyY",
  },
} as const;

const ALT_TEXT_MAP: Record<string, string> = {
  qlKaN7eqay8: "aerial view of city during golden hour",
  "hRg1KL4-AUE": "body of water under white cloudy sky",
  "6wVqCeK030Y": "four people sit on park benches across city scape",
  SYHsBaRDWBQ: "city of Toronto Canada",
  IJHVtGZrDDE: "aerial view of city buildings during daytime",
  Onadzzr1yBU: "Toronto building with lights",
  vsFCo5xnpZg: "Toronto Canada near buildings",
  DnATKk5tGns: "two man and woman walking on pathway near high rise buildings",
  Nm7pTBPkGRo: "red and black double decker bus",
  u_KQbigI68g: "red and white train",
  "1zqmWyrUOoU": "person going down to subway station",
  aNHloYjAkC8: "people walking on pedestrian lane beside red and white train",
  W_MUqtuHwyY: "brown building near intersection",
};

interface PictureProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  id?: string;
  nativeAspectRatio?: boolean;
}

export const Picture = ({
  id,
  nativeAspectRatio = false,
  ...props
}: PictureProps) => {
  if (typeof id === "string" && id.length > 0) {
    return (
      <Image
        $nativeAspectRatio={nativeAspectRatio}
        src={`https://source.unsplash.com/${id}`}
        alt={ALT_TEXT_MAP[id]}
        {...props}
      />
    );
  }

  return <Image $nativeAspectRatio={nativeAspectRatio} {...props} />;
};
