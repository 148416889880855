import { useTranslation } from "react-i18next";
import { Typography } from "../components/Typography";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Table, TableContainer, TableData } from "../components/Table";
import { Link } from "../components/Link";

const SEMINARS = [
  {
    time: "8:00 PM — 8:25 PM",
    presenters: [{ wcaId: "2014RICH04", name: "Sam Richard" }],
    title: "My Solving Journey",
    video: "https://www.youtube.com/watch?v=QYNrO34Nv5w&t=150s",
  },
  {
    time: "8:30 PM — 8:50 PM",
    presenters: [
      { wcaId: "2016KAMA04", name: "AJ Kamal" },
      { wcaId: "2015WIDD01", name: "Owen Widdis" },
    ],
    title: "The Importance of FMC and How You Can Do It Too!",
    video: "https://www.youtube.com/watch?v=QYNrO34Nv5w&t=1853s",
  },
  {
    time: "8:55 PM — 9:15 PM",
    presenters: [
      { wcaId: "2015CHER07", name: "Tommy Cherry" },
      { wcaId: "2019KOBE03", name: "Elliott Kobelansky" },
      { wcaId: "2017SWOR01", name: "Noah Swor" },
    ],
    title: "The Future of 3x3 Blindfolded: New Theory and Recent Advancements",
    video: "https://www.youtube.com/watch?v=QYNrO34Nv5w&t=3108s",
  },
  {
    time: "9:20 PM — 9:35 PM",
    presenters: [{ wcaId: "2015BART05", name: "Aiden Bartlett" }],
    title: "How to Remember What It Feels like to Improve",
    video: "https://www.youtube.com/watch?v=QYNrO34Nv5w&t=4893s",
  },
] as const;

const WCA_PROFILE = "https://www.worldcubeassociation.org/persons/";

export const Seminars = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Section>
        <Typography as="h1">{t("seminarsPage.title")}</Typography>
        <Line />
        <Typography>{t("seminarsPage.body")}</Typography>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <td>
                  <Typography isBold>{t("seminarsPage.table.time")}</Typography>
                </td>
                <td>
                  <Typography isBold>
                    {t("seminarsPage.table.presenters")}
                  </Typography>
                </td>
                <td>
                  <Typography isBold>
                    {t("seminarsPage.table.title")}
                  </Typography>
                </td>
              </tr>
            </thead>
            <tbody>
              {SEMINARS.map((seminar) => (
                <tr key={seminar.title}>
                  <TableData>
                    <Typography>{seminar.time}</Typography>
                  </TableData>
                  <TableData>
                    {seminar.presenters.map(
                      ({ name, wcaId }, index, presenters) => (
                        <Typography key={wcaId}>
                          <Link to={`${WCA_PROFILE}${wcaId}`}>{name}</Link>
                          {index !== presenters.length - 1 && ", "}
                        </Typography>
                      )
                    )}
                  </TableData>
                  <TableData>
                    <Typography>
                      <Link to={seminar.video}>{seminar.title}</Link>
                    </Typography>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Section>
    </Layout>
  );
};
