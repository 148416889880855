import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Layout, Section } from "../components/Layout";
import { ProcessedEventDay } from "../types";
import { getSchedule, processSchedule } from "../services/wcif";
import { ScheduleItem } from "../components/ScheduleItem";
import { getLocalizedPath, LOCALES } from "../services/locale";
import { HeaderLink } from "../components/HeaderLink";
import { MENU_HEIGHT } from "../components/Menu";
import { Typography } from "../components/Typography";
import { Line } from "../components/Line";
import { Link } from "../components/Link";

const WCA_SCHEDULE =
  "https://www.worldcubeassociation.org/competitions/NAC2022#competition-schedule";

const PDF_SCHEDULE =
  "https://www.worldcubeassociation.org/competitions/NAC2022.pdf";

export const Schedule = () => {
  const { t } = useTranslation();
  const refs = useRef<Record<string, HTMLDivElement | null>>({});
  const { locale: currentLocale, date } = useParams() as {
    locale: keyof typeof LOCALES;
    date: string;
  };

  const [schedule, setSchedule] = useState<Record<string, ProcessedEventDay>>(
    {}
  );

  useEffect(() => {
    getSchedule().then((schedule) => {
      const processedSchedule = processSchedule(schedule);
      setSchedule(processedSchedule);
    });
  }, []);

  const scheduleLoaded = Object.keys(schedule).length > 0;

  // TODO: the inner logic of this hook is duplicated in FAQ.tsx
  useLayoutEffect(() => {
    if (scheduleLoaded && typeof date === "string") {
      const ref = refs.current[date];
      if (ref !== undefined && ref !== null) {
        window.scrollTo({
          top: ref.offsetTop - MENU_HEIGHT,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [date, scheduleLoaded]);

  return (
    <Fragment>
      <Layout>
        <Section>
          <Typography as="h1">{t("schedule")}</Typography>
          <Line />
          <Typography>
            <Trans
              i18nKey="schedulePage.body"
              components={{
                wcaWebsiteSchedule: <Link to={WCA_SCHEDULE} />,
                pdfSchedule: <Link to={PDF_SCHEDULE} />,
              }}
            />
          </Typography>
        </Section>
      </Layout>
      {Object.entries(schedule).map(([key, { formattedDate, events }]) => (
        <Layout key={key}>
          <Section ref={(el) => (refs.current[key] = el)}>
            <HeaderLink
              to={getLocalizedPath(currentLocale, ["schedule", key].join("/"))}
            >
              {formattedDate}
            </HeaderLink>
            {Object.values(events).map((event) => (
              <ScheduleItem key={event.id} {...event} />
            ))}
          </Section>
        </Layout>
      ))}
    </Fragment>
  );
};
