/* eslint-disable jsx-a11y/anchor-has-content */
import { useTranslation, Trans } from "react-i18next";
import styled from "styled-components";
import { Typography } from "../components/Typography";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Link } from "../components/Link";
import { getLocalizedPath } from "../services/locale";

const StyledList = styled.ul`
  li {
    margin: 12px 0;
  }
`;

export const Covid = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section>
          <Typography as="h1" data-testid="header">
            {t("covid-19")}
          </Typography>
          <Line />
          <Typography isBold data-testid="last-updated">
            {t("covid.lastUpdated")}
          </Typography>
          <br />
          <br />
          <Typography data-testid="text-block">
            {[1, 2, 3].map((n) => t(`covid.p.${n}`)).join("\n\n")}
          </Typography>
          <Typography>
            <StyledList data-testid="list">
              {[1, 2, 3, 4].map((n) => (
                <li key={n}>
                  <Typography>
                    <Trans
                      i18nKey={`covid.ul.${n}`}
                      components={{
                        a1: (
                          <Link
                            data-testid="link-1"
                            to="https://www.toronto.ca/home/covid-19/covid-19-what-you-should-do/covid-19-what-to-do-if-you-have-covid-19-symptoms/"
                          />
                        ),
                        a2: (
                          <Link
                            data-testid="link-2"
                            to="https://www.toronto.ca/home/covid-19/covid-19-reduce-virus-spread/"
                          />
                        ),
                        i: <Typography isItalic />,
                        rubiksCup: (
                          <Link to={getLocalizedPath("en", "rubiks-cup")} />
                        ),
                      }}
                    />
                  </Typography>
                </li>
              ))}
            </StyledList>
          </Typography>
          <Typography data-testid="text-block">
            {[4, 5, 6, 7].map((n) => t(`covid.p.${n}`)).join("\n\n")}
          </Typography>
        </Section>
      </Layout>
    </>
  );
};
