import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "./style";
import { Typography } from "./Typography";

const LinkBase = styled(NavLink)`
  text-decoration: none;
  color: ${COLORS.BLACK};
  &:hover,
  &.active {
    text-decoration: underline;
  }
`;

interface Props {
  to: string;
  children: React.ReactNode;
  as?: "h1" | "h2" | "h3";
}

export const HeaderLink = ({ to, children, as = "h2", ...rest }: Props) => (
  <LinkBase to={to} {...rest}>
    <Typography as={as}>{children}</Typography>
  </LinkBase>
);
