import { useTranslation } from "react-i18next";
import { Typography } from "../components/Typography";
import { IMAGES, Picture } from "../components/Picture";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import events from "../data/events.json";
import { Table, TableContainer } from "../components/Table";

// TODO localization?
const HEADINGS: Array<{ name: string; help?: string }> = [
  { name: "Event" },
  {
    name: "Qualification Requirement",
    help: "You must get this result or better at an official WCA competition that finished taking place by June 13, 2022.",
  },
  {
    name: "Format",
  },
  {
    name: "Time Limit",
  },
];

const MAX_NUMBER_OF_ROUNDS = 4;

const FORMAT_ABBR_TO_FULL: Record<string, string> = {
  Ao5: "Average of 5",
  Mo3: "Mean of 3",
  Bo3: "Best of 3",
  Bo2: "Best of 2",
};

const RANKS = [1, 2, 3] as const;

const EVENT_PRIZE_TYPES = ["333", "other"] as const;

export const Events = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section>
          <Typography as="h1" data-testid="header">
            {t("eventsPage.title")}
          </Typography>
          <Line />
          <Typography data-testid="before-table">
            {t("eventsPage.beforeTable")}
          </Typography>
          <TableContainer>
            <Table data-testid="table">
              <thead data-testid="head">
                <tr>
                  <td>&nbsp;</td>
                  {new Array(MAX_NUMBER_OF_ROUNDS).fill(0).map((_, index) => (
                    <td colSpan={index === 0 ? 3 : 1} key={index}>
                      <Typography isBold>Round {index + 1}</Typography>
                    </td>
                  ))}
                </tr>
                <tr>
                  {HEADINGS.map(({ name, help }) => {
                    if (help !== undefined) {
                      return (
                        <td key={name}>
                          <abbr title={help}>
                            <Typography isBold>{name}</Typography>
                          </abbr>
                        </td>
                      );
                    }

                    return (
                      <td key={name}>
                        <Typography isBold>{name}</Typography>
                      </td>
                    );
                  })}
                  {new Array(MAX_NUMBER_OF_ROUNDS - 1)
                    .fill(0)
                    .map((_, index) => (
                      <td key={index}>
                        <abbr title="Number of competitors in the round">
                          <Typography isBold>Num</Typography>
                        </abbr>
                      </td>
                    ))}
                </tr>
              </thead>
              <tbody data-testid="body">
                {events.map(({ name, format, limit, rounds }) => {
                  const [firstRound, ...rest] = rounds;

                  return (
                    <tr key={name}>
                      <td>
                        <Typography>{name}</Typography>
                      </td>
                      <td>
                        <Typography>{firstRound}</Typography>
                      </td>
                      <td>
                        <abbr title={FORMAT_ABBR_TO_FULL[format]}>
                          <Typography>{format}</Typography>
                        </abbr>
                      </td>
                      <td>
                        <Typography>{limit}</Typography>
                      </td>
                      {rest.map((round, index) => (
                        <td key={index}>
                          <Typography>{round}</Typography>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
          <Typography data-testid="after-table">
            {[1, 2, 3].map((n) => t(`eventsPage.afterTable.${n}`)).join("\n\n")}
          </Typography>
        </Section>
      </Layout>
      <Layout>
        <Section data-testid="prizes-section">
          <Typography as="h2" data-testid="sub-header">
            {t("eventsPage.prizes.title")}
          </Typography>
          <Line />
          <Table data-testid="prize-table">
            <thead data-testid="head">
              <tr>
                <td>&nbsp;</td>
                {EVENT_PRIZE_TYPES.map((type) => (
                  <td key={type}>
                    <Typography isBold>
                      {t(`eventsPage.prizes.${type}.title`)}
                    </Typography>
                  </td>
                ))}
              </tr>
            </thead>
            <tbody data-testid="body">
              {RANKS.map((i) => {
                const rank = i - 1;

                return (
                  <tr key={rank} data-testid={`row-${rank}`}>
                    <td>
                      <Typography>
                        {t(`eventsPage.prizes.ranks.${rank}`)}
                      </Typography>
                    </td>
                    {EVENT_PRIZE_TYPES.map((type) => (
                      <td key={type}>
                        <Typography>
                          {t(`eventsPage.prizes.${type}.amounts.${rank}`)}
                        </Typography>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <br />
          <Typography isItalic>
            {t("eventsPage.prizes.canadianDollars")}
          </Typography>
        </Section>
        <Section>
          <Picture id={IMAGES.ttc[3]} />
        </Section>
      </Layout>
    </>
  );
};
