import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import { Typography } from "../components/Typography";
import { Link } from "../components/Link";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { LinkButton } from "../components/LinkButton";
import { getLocalizedPath } from "../services/locale";
import { Table, TableContainer, TableData } from "../components/Table";
import teams from "../data/rubiksCupTeams.json";

const StyledList = styled.ol`
  li {
    margin: 12px 0;
  }
`;

const HeadingWithMargin = styled(Typography)`
  margin-top: 20px;
`;

const GOOGLE_FORM = "https://forms.gle/e9XUjU5sEg8dMNcy9";

const WCA_PROFILE = "https://www.worldcubeassociation.org/persons/";

const LIVESTREAM = "https://www.youtube.com/watch?v=-Ts74zJXByI&t=486s";

export const RubiksCup = () => {
  const { t } = useTranslation();

  return (
    <>
      <Layout>
        <Section>
          <Typography as="h1">{t("rubiksCup.title")}</Typography>
          <Line />
          <Typography>{t("rubiksCup.body")}</Typography>

          <br />
          <br />
          <LinkButton to={LIVESTREAM}>
            {t("rubiksCup.reWatchTheEvent")}
          </LinkButton>

          <HeadingWithMargin as="h2">
            {t("rubiksCup.teams.title")}
          </HeadingWithMargin>
          <Line />
          <Typography>{t("rubiksCup.teams.body")}</Typography>
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <td>
                    <Typography isBold>{t("rubiksCup.teams.seed")}</Typography>
                  </td>
                  <td>
                    <Typography isBold>{t("rubiksCup.teams.name")}</Typography>
                  </td>
                  <td>
                    <Typography isBold>
                      {t("rubiksCup.teams.teamMembers")}
                    </Typography>
                  </td>
                  <td>
                    <Typography isBold>{t("rubiksCup.teams.time")}</Typography>
                  </td>
                </tr>
              </thead>
              <tbody>
                {teams.map((team, index) => (
                  <tr key={team.name}>
                    <td>
                      <Typography>{index + 1}</Typography>
                    </td>
                    <TableData>
                      <Typography>{team.name}</Typography>
                    </TableData>
                    <TableData>
                      {team.members.map(
                        ({ name, wcaId }, index, presenters) => (
                          <Typography key={wcaId}>
                            <Link to={`${WCA_PROFILE}${wcaId}`}>{name}</Link>
                            {index !== presenters.length - 1 && ", "}
                          </Typography>
                        )
                      )}
                    </TableData>
                    <TableData>
                      <Typography>{team.time}</Typography>
                    </TableData>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>

          <HeadingWithMargin as="h2">
            {t("rubiksCup.format.heading")}
          </HeadingWithMargin>
          <Line />
          <Typography>
            <StyledList>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((n) => (
                <li key={n}>
                  <Typography>
                    <Trans i18nKey={`rubiksCup.format.${n}`} />
                  </Typography>
                </li>
              ))}
            </StyledList>
          </Typography>

          <HeadingWithMargin as="h2">
            {t("rubiksCup.spectatorInfo.title")}
          </HeadingWithMargin>
          <Line />
          <Typography>
            <Trans
              i18nKey="rubiksCup.spectatorInfo.body"
              components={{
                a: <Link to={getLocalizedPath("en", "covid-19")} />,
              }}
            />
          </Typography>
          <Typography>{t("rubiksCup.spectatorInfo.venue")}</Typography>
          <Typography>{t("rubiksCup.spectatorInfo.time")}</Typography>

          <HeadingWithMargin as="h2">
            {t("rubiksCup.submitYourTeam.title")}
          </HeadingWithMargin>
          <Line />
          <Typography isBold>{t("rubiksCup.submitYourTeam.closed")}</Typography>
          <br />
          <br />
          <Typography>
            <Trans
              i18nKey="rubiksCup.submitYourTeam.body"
              components={{
                a: <Link to={GOOGLE_FORM} />,
              }}
            />
          </Typography>
          <br />
          <br />
          <LinkButton to={GOOGLE_FORM}>
            {t("rubiksCup.submitYourTeam.cta")}
          </LinkButton>
        </Section>
      </Layout>
    </>
  );
};
