import styled, { css } from "styled-components";
import { COLORS, FONT_FAMILY } from "./style";
import { Typography } from "./Typography";

const fontStyles = css`
  font-size: 1.25rem;
  font-family: ${FONT_FAMILY};
`;

const inputStyles = css`
  margin-bottom: 20px;
  color: ${COLORS.BLACK};
  ${fontStyles}
  border: ${COLORS.BLACK} solid 1px;
  padding: 10px 5px;
`;

const webkitNone = css`
  -webkit-appearance: none;
  border-radius: 0;
`;

export const TextInput = styled.input`
  ${inputStyles}
  ${webkitNone}
`;

export const TextArea = styled.textarea`
  ${inputStyles}
  ${webkitNone}
  min-height: 8rem;
`;

type LabelProps = {
  isBold?: boolean;
} & React.LabelHTMLAttributes<HTMLLabelElement>;

export const Label = ({ children, isBold, ...props }: LabelProps) => (
  <label {...props}>
    <Typography isBold={isBold}>{children}</Typography>
  </label>
);

export const Submit = styled.input.attrs({ type: "submit" })`
  ${fontStyles};
  ${webkitNone};
  background-color: ${COLORS.BLACK};
  color: ${COLORS.WHITE};
  padding: 0.75rem 1.25rem;
  border: none;
  cursor: pointer;
  outline-color: ${COLORS.RED};
  transition: background-color 0.3s;
  &:not([aria-disabled="true"]) {
    &:hover {
      background-color: ${COLORS.GREY};
    }
  }
  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
`;
