/* eslint-disable jsx-a11y/anchor-has-content */
import { Fragment, useLayoutEffect, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { HeaderLink } from "../components/HeaderLink";
import { Layout, Section } from "../components/Layout";
import { Line } from "../components/Line";
import { Link } from "../components/Link";
import { MENU_HEIGHT } from "../components/Menu";
import { Typography } from "../components/Typography";
import { getLocalizedPath, FAQKeys, LOCALES } from "../services/locale";

type FAQItem = {
  key: FAQKeys;
  link?: string;
};

const generalQuestions: FAQItem[] = [
  { key: "cancelling-registration" },
  { key: "are-spectators-allowed", link: getLocalizedPath("en", "rubiks-cup") },
  { key: "photos-and-videos" },
  { key: "can-my-organization-sponsor" },
];

const qualificationQuestions: FAQItem[] = [
  {
    key: "how-do-i-qualify",
    link: "https://worldcubeassociation.org/competitions",
  },
  { key: "can-i-register-if-i-dont-qualify" },
  { key: "what-happens-if-dont-qualify" },
  { key: "register-without-any-results" },
];

const travelKeys = [
  "hotel-rate",
  "air-travel",
  "airport-to-venue",
  "closest-train-station",
] as const;

const travelQuestions: FAQItem[] = travelKeys.map((key) => ({
  key,
  link: getLocalizedPath("en", "travel"),
}));

export const FAQ = () => {
  const questionRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const { locale: currentLocale, questionId } = useParams() as {
    locale: keyof typeof LOCALES;
    questionId: string;
  };
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (typeof questionId === "string") {
      const questionRef = questionRefs.current[questionId];
      if (questionRef !== undefined && questionRef !== null) {
        window.scrollTo({
          top: questionRef.offsetTop - MENU_HEIGHT,
          left: 0,
          behavior: "smooth",
        });
      }
    }
  }, [questionId]);

  const QuestionBlock = ({
    questions,
    headerKey,
  }: {
    questions: FAQItem[];
    headerKey: string;
  }) => {
    return (
      <Fragment>
        <Layout>
          <Section>
            <Typography as="h1" data-testid="question-block-header">
              {t(`faqs.${headerKey}`)}
            </Typography>
            <Line />
          </Section>
        </Layout>
        {questions.map(({ key, link }) => (
          <Layout key={key}>
            <Section ref={(el) => (questionRefs.current[key] = el)}>
              <HeaderLink
                as="h3"
                to={getLocalizedPath(currentLocale, ["faq", key].join("/"))}
                data-testid="question"
              >
                {t(`faqs.q.${key}`)}
              </HeaderLink>
            </Section>
            <Section>
              <Typography data-testid="answer">
                <Trans
                  i18nKey={`faqs.a.${key}`}
                  components={{
                    a: <Link to={link as string} data-testid="link" />,
                    i: <Typography isItalic />,
                    b: <Typography isBold />,
                  }}
                />
              </Typography>
            </Section>
          </Layout>
        ))}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <QuestionBlock questions={generalQuestions} headerKey="general" />
      <QuestionBlock
        questions={qualificationQuestions}
        headerKey="qualification"
      />
      <QuestionBlock questions={travelQuestions} headerKey="travel" />
    </Fragment>
  );
};
